import { Redirect, Route, Switch } from "react-router-dom";
import Login from "./components/login/Login";
import { CustomerWrapper, useCustomer } from "./context/user";
import { DataContextWrapper } from "./context/ductTestData";
import { TkalDataContextWrapper } from "./context/tkalData";
import { AhuDataContextWrapper } from "./context/ahuData";
import Layout from "./layout/Layout";
import LoginRedirect from "./components/login/LoginRedirect";
import StartPage from "./pages/duct-test/StartPage";
import AhuTestStartPage from "./pages/ahu-test/AhuTestStartPage";
import NavigationPortalPage from "./pages/navigation-portal/NavigationPortalPage";
import AdminPage from "./pages/admin/AdminPage";
import KalorifeerTestPage from "./pages/kalorifeer-test/KalorifeerTestPage";
import AhuTestingEstoniaPage from "./pages/ahu-test-estonia/AhuTestingEstoniaPage";
import CanopyPage from "./pages/canopy/CanopyPage";
import OzonatorTestPage from "./pages/ozonator/OzonatorTestPage";
import SepuTestPage from "./pages/sepu/SepuTestPage";
import ControlPanelTestPage from "./pages/control-panel/ControlPanelTestPage";
import ProtectiveEquipmentPage from "./pages/protective-equipment/ProtectiveEquipmentPage";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return (
    <CustomerWrapper>
      <DataContextWrapper>
        <TkalDataContextWrapper>
          <AhuDataContextWrapper>
            <Layout>
              <Route exact path="/login" component={Login} />
              <Route exact path="/login/:guid" component={LoginRedirect} />
              <CustomerRoutes />
            </Layout>
          </AhuDataContextWrapper>
        </TkalDataContextWrapper>
      </DataContextWrapper>
    </CustomerWrapper>
  );
}
const CustomerRoutes = () => {
  const { logged } = useCustomer();
  return (
    <Switch>
      <Route
        exact
        path="/portal"
        render={() => {
          if (logged) return <NavigationPortalPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/"
        render={() => {
          if (logged) return <StartPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/sweden"
        render={() => {
          if (logged) return <StartPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/admin"
        render={() => {
          if (logged) return <AdminPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/recair"
        render={() => {
          if (logged) return <AhuTestStartPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/tkal"
        render={() => {
          if (logged) return <KalorifeerTestPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/ahu-tallinn"
        render={() => {
          if (logged) return <AhuTestingEstoniaPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/canopy"
        render={() => {
          if (logged) return <CanopyPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/ozonator"
        render={() => {
          if (logged) return <OzonatorTestPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/sepu"
        render={() => {
          if (logged) return <SepuTestPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/control-panel"
        render={() => {
          if (logged) return <ControlPanelTestPage />;
          return <Redirect to="/login" />;
        }}
      />
      <Route
        exact
        path="/protective-equipment"
        render={() => {
          if (logged) return <ProtectiveEquipmentPage />;
          return <Redirect to="/login" />;
        }}
      />
    </Switch>
  );
};
