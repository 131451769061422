import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loading from "../components/common/Loading";
import authqueries, { instance } from "../components/queries/auth";

interface props {
  logged: boolean,
  setLogged: React.Dispatch<React.SetStateAction<boolean>>,
  login: (guid: string) => Promise<void>,
  logout: () => void,
  getCurrentCountry: () => string | undefined
  setCountry: React.Dispatch<React.SetStateAction<string | undefined>>
  permissions: string[],
}

export const CustomerContext = createContext({} as props);

export const useCustomer = () => useContext(CustomerContext);

export const CustomerWrapper = ({ children }: any) => {
  const [logged, setLogged] = useState(false);
  const [working, setWorking] = useState(true);
  const [country, setCountry] = useState<string>();
  const [permissions, setPermissions] = useState<string[]>([]);
  const history = useHistory();
  const { i18n } = useTranslation();

  const refreshToken = async () => {
    if (localStorage.getItem("accessToken") === null || localStorage.getItem("refreshToken") === null) {
      setWorking(false)
      return;
    }
    try {
      var encodedRefreshToken = encodeURIComponent(localStorage.getItem("refreshToken")!);
      var encodedAccessToken = encodeURIComponent(localStorage.getItem("accessToken")!);
      const response = await authqueries.refresh(encodedAccessToken, encodedRefreshToken);
      instance.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
      localStorage.setItem("refreshToken", response.data.newRefreshToken);
      localStorage.setItem("accessToken", response.data.token);
      setPermissions(response.data.permissions)
      setLogged(true);
      navigateUser(response.data.permissions)

      return response.data.token
    }
    catch {
      localStorage.clear();
      sessionStorage.clear();
      setLogged(false)
    }
    finally { setWorking(false) }
  };

  const login = async (guid: string) => {
    try {
      var response = await authqueries.login(guid);
      instance.defaults.headers.common["Authorization"] = "Bearer " + response.data.token
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem(("accessToken"), response.data.token)
      setPermissions(response.data.permissions)
      setLogged(true);

      navigateUser(response.data.permissions)
    }
    catch (err: any) {

      console.log(err.response.data)
      if (err.response.data === "Login_UserNotFound") {
        alert("You are not authorized to use this application");
      }
      history.push("/login")
    }
  }

  const navigateUser = (userPermissions: string[]) => {
    const hasAhu = userPermissions.find((userPermissions) => userPermissions === 'PAhuTester') !== undefined;
    const hasAhuEst = userPermissions.find((userPermissions) => userPermissions === 'PAhuTesterEst') !== undefined;
    const hasNtoEst = userPermissions.find((userPermissions) => userPermissions === 'PNtoTesterEst') !== undefined;
    const hasNtoSweden = userPermissions.find((userPermissions) => userPermissions === 'PNtoTesterSwe') !== undefined;
    const hasTkal = userPermissions.find((userPermissions) => userPermissions === 'PTkalTester') !== undefined;
    const hasCanopy = userPermissions.find((userPermissions) => userPermissions === 'PCanopyTester') !== undefined;
    const hasOzonator = userPermissions.find((userPermissions) => userPermissions === 'POzonatorTester') !== undefined;
    const hasSepu = userPermissions.find((userPermissions) => userPermissions === 'PSepuTester') !== undefined;
    const hasControlPanel = userPermissions.find((userPermissions) => userPermissions === 'PControlPanelTester') !== undefined;
    const hasProtectiveEquipment = userPermissions.find((userPermissions) => userPermissions === 'PProtectiveEquipmentForms') !== undefined;

    if ((hasNtoEst && hasNtoSweden) || (hasNtoEst && hasAhu) || (hasNtoSweden && hasAhu) || (hasTkal && hasNtoEst) || (hasOzonator && hasControlPanel)) {
      history.push("/portal")
      return;
    }
    if (hasAhu === true) {
      history.push("/recair")
      return;
    }
    if (hasTkal === true) {
      history.push("/tkal")
      return;
    }
    if (hasNtoSweden === true) {
      setCountry("sweden")
      sessionStorage.setItem("country", "sweden")
      i18n.changeLanguage("sv")
      history.push("/sweden")
      return;
    }
    if (hasNtoEst === true) {
      history.push("/")
      return;
    }
    if (hasAhuEst === true) {
      history.push("/ahu-tallinn")
      return;
    }
    if (hasCanopy === true) {
      history.push("/canopy")
      return;
    }
    if (hasOzonator === true) {
      history.push("/ozonator")
      return;
    }
    if (hasSepu) {
      history.push("/sepu")
      return;
    }
    if (hasControlPanel) {
      history.push("/control-panel")
      return;
    }
    if (hasProtectiveEquipment) {
      history.push("/protective-equipment")
      return;
    }
    else {
      history.push("/portal")
      return;
    }
  }

  const getCurrentCountry = () => {
    return country;
  }

  const logout = () => {
    authqueries.logout().finally(() => {
      setLogged(false);
      localStorage.removeItem("user");
      localStorage.removeItem("email");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      sessionStorage.clear();
      delete instance.defaults.headers.common["Authorization"];
      history.push("/login");
    });
  };

  const requestArray: any[] = [];

  useEffect(() => {
    instance.interceptors.response.use(
      (response) => {
        if (requestArray.length !== 0) {
          requestArray.forEach((x, i) => {
            if (response.config.url === x.url) {
              requestArray.splice(i, 1)
            }
          })
        }
        return response;
      },
      async (error: any) => {
        const originalRequest = error.config;
        requestArray.push(originalRequest);
        if (error.response!.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const accessToken = await refreshToken();
          if (requestArray.length !== 0) {
            requestArray.forEach(x => {
              x.headers['Authorization'] = "Bearer " + accessToken;
            })
          }
          return instance(originalRequest)
        }
        return Promise.reject(error)
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    refreshToken()
    // eslint-disable-next-line
  }, [])

  return (
    <CustomerContext.Provider value={{ setLogged, logged, login, logout, getCurrentCountry, setCountry, permissions }}>
      {working ? <Loading /> : children}
    </CustomerContext.Provider>
  );
};