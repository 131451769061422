import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap'
import { addUser, findUser, getRoles, removeUser } from '../../components/queries/adminQueries';
import { useDataContext } from '../../context/ductTestData';
import { AddUserRequest } from '../../types/admin/AddUserRequest';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import AddPrinterModal from '../../components/admin/AddPrinterModal';

const AdminPage = () => {

    interface UserResponse {
        id: string,
        email: string,
        fullName: string,
        countryCode: string,
    }

    const [id, setId] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [roles, setRoles] = useState<string[]>([]);
    const [fullName, setFullName] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [userToRemove, setUserToRemove] = useState<string>("");
    const [userToRemoveData, setUserToRemoveData] = useState<UserResponse[]>([]);
    const { setIsLoading } = useDataContext();
    const { t } = useTranslation();

    const handleChangeSelect = (e: any) => {
        setRole(e.target.value)
    }
    const handleChangeSelectCountry = (e: any) => {
        setCountry(e.target.value)
    }
    const handleFormSubmit = (e: any) => {
        e.preventDefault();

        if (!email || !role || !fullName || !country) {
            return alert("Fill in all fields");
        }
        const normalizedEmail = (email.toLowerCase())
        const userName = (normalizedEmail.split("@")[0])

        const userRequest: AddUserRequest = ({
            RequestUser: {
                NormalizedEmail: normalizedEmail,
                UserName: userName,
                Email: email,
                FullName: fullName,
                CountryCode: country,
                isEnabled: true
            },
            RequestRole: {
                Name: role
            }
        });
        setIsLoading(true)
        addUser(userRequest).then((res) => { }).catch((err) => { console.log(err) }).finally(() => { setIsLoading(false) });
    }

    const handleSearchUser = () => {
        findUser(userToRemove).then((res: AxiosResponse<UserResponse[]>) => { setUserToRemoveData(res.data) })
            .catch((err) => {
                alert(err.response.data)
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    const handleRemoveUser = (id: string) => {
        setId(id)
        if (window.confirm("Are you sure?")) {
            removeUser(id).then((res) => { }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false)
            });
            setUserToRemoveData([])
        }
    }

    const fetchRoles = () => {
        getRoles().then((response: AxiosResponse<string[]>) => {
            setRoles(response.data)
        }).catch((error) => {
            console.error(error)
        })
    }

    useEffect(() => {
        fetchRoles()
    }, [])

    return (
        <div className="mainPageLayout">
            <form className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "5%", gap: "25px" }} onSubmit={handleFormSubmit}>
                <Label className="portal-page__label" >Configure new user</Label>
                <input className="input-orderNr" style={{ width: "50%" }} type="email" onChange={e => setEmail(e.target.value)} value={email ? email : ""} placeholder="Email" />
                <input className="input-orderNr" style={{ width: "50%" }} type="text" onChange={e => setFullName(e.target.value)} value={fullName ? fullName : ""} placeholder="Full Name" />
                <select className="mainPageForm-select" style={{ width: "50%" }} name="userRole" title="Choose user country" placeholder="Choose user country" onChange={(e: any) => handleChangeSelectCountry(e)} value={country ? country : ""} >
                    <option value="" disabled hidden>Select country</option>
                    <option value="EE">Estonia</option>
                    <option value="FI">Finland</option>
                    <option value="SE">Sweden</option>
                </select>
                <select className="mainPageForm-select" style={{ width: "50%" }} name="userRole" title="Choose user role" placeholder="Choose user role" onChange={(e: any) => handleChangeSelect(e)} value={role ? role : ""} >
                    <option value="" disabled hidden>Select role</option>
                    {roles.map((x, idx) =>
                    (
                        <option key={idx} value={x}>{x}</option>
                    ))}
                </select>
                <Button className="button-primary" style={{ width: "125px" }} type="submit">Add</Button>
            </form>
            <div className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "2%", gap: "25px" }} >
                <Label className="mainPageForm-label" >Remove user</Label>
                <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                    <input className="input-orderNr" style={{ width: "50%" }} type="text" onChange={e => setUserToRemove(e.target.value)} value={userToRemove ? userToRemove : ""} placeholder="Search user" />
                    <Button className="button-primary" style={{ width: "94px" }} onClick={handleSearchUser}>{t("Search")}</Button>
                </div>

                {userToRemoveData.map((user, idx) =>
                    <div key={idx} style={{ marginTop: "1%" }} className={`orderDataOrders${(user.id === id) ? " active" : ""}`} onClick={() => setId(user.id)}>
                        {user.email}
                        <IconButton onClick={() => handleRemoveUser(user.id)} >
                            <ClearIcon />
                        </IconButton>
                    </div>
                )}
            </div>
            <AddPrinterModal />
        </div>
    )
}
export default AdminPage