import React, { useEffect, useState } from 'react'
import { Button, Label } from 'reactstrap'
import { useTkalDataContext } from '../../context/tkalData';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from 'react-i18next';
import Stopwatch from '../../components/configurationForms/kalorifeer/Stopwatch';
import { saveKalorifeerTest } from '../../components/queries/testQueries';
import { AxiosResponse } from 'axios';
import { Kalorifeer } from '../../types/kalorifeer/Kalorifeer';

const LeakTest = () => {
    const { tkalData, setTkalData } = useTkalDataContext();
    const [leakTestStartTime, setLeakTestStartTime] = useState<Date | undefined>(new Date(tkalData.leakTest?.startDateTime) ? new Date(tkalData.leakTest?.startDateTime) : undefined);
    const { t } = useTranslation();

    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value

        if (value === "" && (name === "endPressure" || name === "startPressure")) {
            // Set the value to 0 in the tkalData object based on the input field name
            setTkalData((prevState) => ({
                ...prevState,
                leakTest: {
                    ...prevState.leakTest,
                    [name]: 0,
                },
            }));
            return;
        }
        setTkalData({ ...tkalData, leakTest: { ...tkalData.leakTest, [name]: value } })

    }

    const handlePressureDifference = () => {
        if (tkalData.leakTest.endPressure == null || tkalData.leakTest.startPressure == null) return;

        const pressureDifference = (tkalData.leakTest.endPressure - tkalData.leakTest.startPressure);
        if (pressureDifference >= -1 && pressureDifference <= 10) {
            setTkalData({ ...tkalData, leakTest: { ...tkalData.leakTest, testResult: "Ok", pressureDifference: pressureDifference } })
            return;
        }
        if (pressureDifference < -1) {
            setTkalData({ ...tkalData, leakTest: { ...tkalData.leakTest, testResult: "Fail", pressureDifference: pressureDifference } })
            return;
        }
        else {
            setTkalData({ ...tkalData, leakTest: { ...tkalData.leakTest, testResult: "Fail", pressureDifference: pressureDifference } })
            return;
        }
    }

    const handleTypeChange = (type: string) => {
        if (type === "Õhk") {
            setTkalData({ ...tkalData, leakTest: { ...tkalData.leakTest, kalorifeerType: type, startPressure: 25, endPressure: 25 } })
        }
        if (type === "Gaas") {
            setTkalData({ ...tkalData, leakTest: { ...tkalData.leakTest, kalorifeerType: type, startPressure: 48, endPressure: 48 } })
        }
    }

    function isReadonly() {
        if (tkalData.testStatus === "Finished") return true;
        return false;
    }

    const handlePressureTestStart = () => {
        if (tkalData.testStatus === "Finished") return;
        saveKalorifeerTest(tkalData).then((res: AxiosResponse<Kalorifeer>) => {
            setLeakTestStartTime(new Date(res.data.leakTest.startDateTime))
            const startTime = new Date(res.data.leakTest.startDateTime)
            setTkalData({ ...tkalData, leakTest: { ...res.data.leakTest, startDateTime: startTime } });
        }).catch((err) => {
            console.log(err)
        });
    }

    return (
        <div className="flexboxAlignedColumn mainPageForm ahutest-container">
            <Label className="mainPageForm-label">Survetest</Label>

            <div className="test-row" >
                <Label className="test-row--item" style={{ textAlign: "center" }}>Testi tüüp</Label>
            </div>
            <div className="test-row" style={{ gap: "25px" }}>
                <Button disabled={isReadonly()} className={`flexboxAlignedRow orderData orderDataOrders button-selection-1  ${tkalData.leakTest?.kalorifeerType === "Õhk" ? "active" : ""} `} style={{ width: "165px", marginTop: "0" }} onClick={() => handleTypeChange("Õhk")}>Õhk</Button>
                <Button disabled={isReadonly()} className={`flexboxAlignedRow orderData orderDataOrders button-selection-1  ${tkalData.leakTest?.kalorifeerType === "Gaas" ? "active" : ""} `} style={{ width: "165px", marginTop: "0" }} onClick={() => handleTypeChange("Gaas")}>Gaas</Button>
            </div>
            {/* {tkalData.testStatus !== "Finished" && tkalData.leakTest?.startDateTime !== undefined && <Stopwatch startTime={leakTestStartTime} />} */}
            <div className="test-row">
                <Label className="test-row--item" style={{ textAlign: "center" }}>Sisesta algrõhk bar</Label>
            </div>
            <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                <input readOnly={isReadonly()} className="input-primary" name="startPressure" type="number" placeholder="Algrõhk (bar)" value={tkalData.leakTest.startPressure || ""} onChange={handleChange} />
            </div>

            <div className="test-row" >
                <Label className="test-row--item" style={{ textAlign: "center" }}>Sisesta lõpprõhk bar</Label>
            </div>
            <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                <input readOnly={isReadonly()} className="input-primary" name="endPressure" type="number" placeholder="Lõpprõhk (bar)"
                    /*  value={tkalData.leakTest.endPressure === "" ? "" : tkalData.leakTest.endPressure || 0} */
                    value={tkalData.leakTest.endPressure || ""}
                    onChange={handleChange} />
            </div>
            <Label className="mainPageForm-label2">
                {tkalData.leakTest?.testResult === "Ok" ? <div style={{ fontSize: "18px" }}>{t("Survetest läbitud")} : {tkalData?.leakTest?.pressureDifference}bar <DoneIcon style={{ fill: "green" }} /></div> : ""}
                {tkalData.leakTest?.testResult === "Fail" ? <div style={{ fontSize: "18px" }}>{t("Survetest ebaõnnestunud")} : {tkalData?.leakTest?.pressureDifference}bar <CloseIcon style={{ fill: '#FF0000' }} />  </div> : ""}
            </Label>
            <div className="test-row" style={{ gap: "15px" }}>
                <Button disabled={isReadonly()} className="flexboxAlignedRow orderData orderDataOrders button-selection-1" style={{ width: "165px", marginTop: "0" }} onClick={handlePressureDifference}>Kontrolli</Button>
                {/* {tkalData.leakTest?.kalorifeerType === "Gaas" && tkalData.leakTest?.startDateTime === undefined && <Button disabled={isReadonly()} className="flexboxAlignedRow orderData orderDataOrders button-selection-1" style={{ width: "165px", marginTop: "0" }} onClick={handlePressureTestStart}>Start</Button>} */}
            </div>

        </div>
    )
}

export default LeakTest