import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import SelectTkalOrder from '../../components/configurationForms/kalorifeer/SelectTkalOrder'
import { useTkalDataContext } from '../../context/tkalData';
import VisualTest from './VisualTest';
import '../../styles/buttons.css'
import '../../styles/testForm.css'
import '../../styles/tkal-test.css'
import { Kalorifeer, LeakTest as LeakTestType, TEST_TYPE } from '../../types/kalorifeer/Kalorifeer';
import { createNewKalorifeerTest, saveKalorifeerTest, sendTkalTestToDirecto } from '../../components/queries/testQueries';
import { AxiosResponse } from 'axios';
import LeakTest from './LeakTest';
import { IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { TkalPurchaseOrder } from '../../types/kalorifeer/TkalPurchaseOrder';

const KalorifeerTestPage = React.memo(() => {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    let visualTestSteps = 2;
    let leakTestSteps = 2;
    const { testType, setIsLoading, isLoading, tkalData, setTkalData, setOrderData, setSavedTests } = useTkalDataContext();

    const handleCreateTest = () => {
        setIsLoading(true)
        if (testType !== TEST_TYPE.EMPTY)
            createNewKalorifeerTest(tkalData).then((res: AxiosResponse<Kalorifeer>) => {
                const createdDate = new Date(res.data.createdDate)
                setTkalData({ ...tkalData, id: res.data.id, createdDate: createdDate });
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const handleNextButton = () => {

        if (testType === TEST_TYPE.EMPTY) {
            alert("Select test type")
            return;
        }
        if (step === 1 && (tkalData.id === 0 || tkalData.id == null)) {
            setStep(step => step + 1)
            handleCreateTest()
            return;
        }
        else {
            setStep(step => step + 1)
            return;
        }
    }

    const handleBackButton = () => {
        if (step >= 2) {
            setStep(step => step - 1)
        }
    }

    const handleSaveTestData = () => {
        if (tkalData.testStatus === "Finished") return;
        setIsLoading(true)
        saveKalorifeerTest(tkalData).then((res: AxiosResponse<Kalorifeer>) => {
            setSavedTests([])
        }).catch((err) => {
            console.log(err)

        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleSendData = () => {
        if (checkInputs() === false) return
        setIsLoading(true)
        if (tkalData.testStatus === "Finished") return;
        sendTkalTestToDirecto(tkalData).then((res: AxiosResponse<Kalorifeer>) => {
            setTkalData({ leakTest: {} as LeakTestType } as Kalorifeer)
            setOrderData({} as TkalPurchaseOrder)
            setSavedTests([])
            setStep(1)
            alert("Testi saatmine õnnestus")
        }).catch((err) => {
            console.log(err)
            alert("Testi saatmine ebaõnnestus")
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const validateNextStep = () => {
        if (step < visualTestSteps && tkalData.testType === TEST_TYPE.VISUAL) {
            return true;
        }
        if (step < leakTestSteps && tkalData.testType === TEST_TYPE.LEAK) {
            return true;
        }

        else {
            return false;
        }
    }

    const validateLastStep = () => {
        if (step === visualTestSteps && tkalData.testType === TEST_TYPE.VISUAL) {
            return true;
        }
        if (step === leakTestSteps && tkalData.testType === TEST_TYPE.LEAK) {

            return true;
        }
        else {

            return false;
        }
    }

    const chooseFirstStepButtonKey = () => {
        if (step === 1) {
            if (tkalData.id && tkalData.testStatus !== "") return t("Open")
            if (tkalData.id && tkalData.testStatus === "") return t("Next")
            if (!tkalData.id && tkalData.testStatus === "") return t("Next")
        }
        if (step > 1) return t("Next")
    }

    const checkInputs = () => {
        if (tkalData.testType === TEST_TYPE.VISUAL) {
            if (tkalData.visualTest?.measurementType === "Mõõdud") {
                if (tkalData.visualTest?.length === undefined || tkalData.visualTest?.length === 0 || tkalData.visualTest?.length === null) {
                    alert("Sisestage pikkus")
                    return false;
                }
                if (tkalData.visualTest?.crossLength === undefined || tkalData.visualTest?.crossLength === 0 || tkalData.visualTest?.crossLength === null) {
                    alert("Sisestage ristlõike mõõt")
                    return false;
                }
                if (tkalData.visualTest?.crossLengthTestResult === undefined || tkalData.visualTest?.crossLengthTestResult === "" || tkalData.visualTest?.crossLengthTestResult === null) {
                    alert("Kontrollige pikkuste mõõte")
                    return false;
                }
            }
            if (tkalData.visualTest?.measurementType === "Kraadid") {
                if (tkalData.visualTest?.angle === undefined || tkalData.visualTest?.angle === 0 || tkalData.visualTest?.angle === null) {
                    alert("Sisestage Nurk A°")
                    return false;
                }
                if (tkalData.visualTest?.angleTestResult === undefined || tkalData.visualTest?.angleTestResult === "" || tkalData.visualTest?.angleTestResult === null) {
                    alert("Vajutage kontrolli nuppu")
                    return false;
                }
            }

            if (tkalData.visualTest?.weldingResultImage1 === undefined || tkalData.visualTest?.weldingResultImage1 === "" || tkalData.visualTest?.weldingResultImage1 === null) {
                alert("Lisage esimene pilt keevistest")
                return false;
            }
            if (tkalData.visualTest?.weldingResultImage2 === undefined || tkalData.visualTest?.weldingResultImage2 === "" || tkalData.visualTest?.weldingResultImage2 === null) {
                alert("Lisage teine pilt keevistest")
                return false;
            }
            if (tkalData.visualTest?.welderName === undefined || tkalData.visualTest?.welderName === "" || tkalData.visualTest?.welderName === null) {
                alert("Valige keevitaja")
                return false;
            }
            if (tkalData.visualTest?.weldingResult === undefined || tkalData.visualTest?.weldingResult === "" || tkalData.visualTest?.weldingResult === null) {
                alert("Valige kas keeviste pind on ühtlane")
                return false;
            }
            return true;
        }
        if (tkalData.testType === TEST_TYPE.LEAK) {
            if (tkalData.leakTest?.kalorifeerType === undefined || tkalData.leakTest?.kalorifeerType === "" || tkalData.leakTest?.kalorifeerType === null) {
                alert("Vali testi tüüp")
                return false;
            }
            if (tkalData.leakTest?.startPressure === undefined || tkalData.leakTest?.startPressure === 0 || tkalData.leakTest?.startPressure === null) {
                alert("Sisestage algrõhk")
                return false;
            }
            if (tkalData.leakTest?.testResult === "" || tkalData.leakTest?.testResult === undefined || tkalData.leakTest?.testResult === null) {
                alert("Vajuta kontrolli")
                return false;
            }
            if (tkalData.leakTest?.endPressure === 0 || tkalData.leakTest?.endPressure === undefined || tkalData.leakTest?.endPressure === null) {
                alert("Sisestage lõpprõhk")
                return false;
            }
            return true;
        }
    }
    return (
        <div className="mainPageLayout">
            {
                (() => {
                    if (step === 1) {
                        return <SelectTkalOrder />
                    }
                    switch (tkalData.testType) {
                        case TEST_TYPE.VISUAL:
                            switch (step) {
                                case 2:
                                    return <VisualTest />
                            }
                            break;
                        case TEST_TYPE.LEAK:
                            switch (step) {
                                case 2:
                                    return <LeakTest />
                            }
                            break;
                    }
                })()
            }

            <div className="navigation-buttons-container" style={{ marginTop: "2%" }}>
                {step >= 2 && <Button className="button-primary" onClick={handleBackButton}>{t("Back")}</Button>}

                {step > 1 &&
                    <>
                        <IconButton onClick={() => handleSaveTestData()}>
                            <SaveIcon />
                        </IconButton>
                    </>
                }
                {validateNextStep() && <Button className="button-primary" onClick={handleNextButton}>{chooseFirstStepButtonKey()}</Button>}
                {validateLastStep() && <Button className="button-primary" style={{ width: "170px", backgroundColor: "white", color: "red" }} onClick={handleSendData} disabled={isLoading || tkalData.testStatus === "Finished"}>{t("ConfirmAndSend")} </Button>}
            </div>
        </div>

    )
})

export default KalorifeerTestPage