import { useTranslation } from 'react-i18next';
import React, { forwardRef, useRef, useState } from 'react'
import { Button, Label } from 'reactstrap';
import { GetPersons } from '../../queries/testQueries';
import SignatureCanvas from 'react-signature-canvas';
import { Person } from '../../../types/protective-equipment/Person';
import { ProtectiveEquipment } from '../../../types/protective-equipment/ProtectiveEquipment';
import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../../../styles/input.css'

type Props = {
    protectiveEquipment: ProtectiveEquipment;
    setProtectiveEquipment: React.Dispatch<React.SetStateAction<ProtectiveEquipment>>
}

const materialTheme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: "10px",
                    color: "white"
                },
                input: {
                    cursor: "pointer",
                }
            }
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#c00',
                }
            }
        }
    },
    typography: {
        fontFamily: "inherit",
    },
    palette: {
        primary: {
            main: '#c00',
            contrastText: "#fff !important"
        },
        secondary: {
            main: '#c00',
            contrastText: "#fff !important"
        },
        text: {
            primary: '#202020cc',
            secondary: '#c00',
            disabled: '#000',
        },
    },
});

const SelectPerson = ({ protectiveEquipment, setProtectiveEquipment }: Props) => {
    const { t } = useTranslation();
    const [workerCode, setWorkerCode] = useState<string>("");
    const [persons, setPersons] = useState<Person[]>([]);
    const [selectedPersonCode, setSelectedPersonCode] = useState<string>("");
    const [pic, setPic] = useState<string>("")
    const signatureCanvasRef = useRef<any>(null);
    const [signature, setSignature] = useState<string>(protectiveEquipment.signatureBase64 ?? "");
    const [date, setDate] = useState<Date | null>((protectiveEquipment.receivingDate) ?? null);
    const [open, setOpen] = useState<boolean>(false)

    const handleBooleanInputChange = (event: any) => {
        setProtectiveEquipment({
            ...protectiveEquipment,
            [event.target.name]: event.target.checked
        })
    }

    const handleSearchPerson = (event: any) => {
        event.preventDefault();
        GetPersons(workerCode).then((res: any) => {
            setPersons(res.data)
            setProtectiveEquipment({} as ProtectiveEquipment)
        });
    }

    const handleChoosePerson = (person: Person) => {
        setSelectedPersonCode(person.code)
        setProtectiveEquipment({ ...protectiveEquipment, workerDirectoCode: person.code, workerName: person.name })
        setDate(new Date())
    }

    const handleSaveSignature = () => {
        if (signatureCanvasRef.current === null) return;
        const signatureImage = signatureCanvasRef.current.toDataURL();
        setSignature(signatureImage);
        setProtectiveEquipment({ ...protectiveEquipment, signatureBase64: signatureImage })
        return;
    };

    const handleClearSignature = () => {
        setPic("")
        setSignature("")
        if (signatureCanvasRef.current === null) return;
        signatureCanvasRef.current.clear();
    };

    const handleClear = () => {
        setProtectiveEquipment({} as ProtectiveEquipment);
    }
    const handleDateChange = (value: Date, setStateProp: React.Dispatch<React.SetStateAction<Date | null>>) => {
        setProtectiveEquipment({ ...protectiveEquipment, receivingDate: value });
        setStateProp(value)
    }

    return (
        <div className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "3%" }}>
            <form className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "10%" }}>
                <Label className="mainPageForm-label" >{t("Otsige inimest")}</Label>
                <div className="flexboxAlignedRow">
                    <input className="input-orderNr" type="text" onChange={e => setWorkerCode(e.target.value)} value={workerCode ?? ""} placeholder={"Sisesta töötaja nimi"} />
                    <Button className="button-primary" style={{ width: "94px" }} type="submit" onClick={handleSearchPerson}>{t("Search")}</Button>
                </div>

                <div className='orderData'>
                    {persons.map((x, idx) => {
                        return <div key={idx} style={{ height: "100%", minHeight: "70px" }} className={`orderDataOrders${(x.code === selectedPersonCode) ? " active" : ""}`}
                            onClick={(e: any) => handleChoosePerson(x)}>
                            <div className="itemData" >{x.name}</div>
                        </div>
                    })}
                </div>
                {(protectiveEquipment.workerDirectoCode !== undefined) &&
                    <>
                        <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Kättesaamise kuupäev")}</Label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <ThemeProvider theme={materialTheme}>
                                <DatePicker className='time-picker w-50 pointer' label={t("Kättesaamise kuupäev")}
                                    value={date}
                                    onChange={(newValue: any) => handleDateChange(newValue, setDate)}
                                    format="dd/MM/yyyy"
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                    slotProps={{
                                        textField: {
                                            onClick: () => setOpen(true),
                                        },
                                    }}

                                />
                            </ThemeProvider>
                        </LocalizationProvider>
                        <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Valige üleantud varustus")}</Label>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("Tööriided")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" name="workClothes" checked={protectiveEquipment.workClothes ?? false} onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("Turvajalanõud")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" name="safetyShoes" checked={protectiveEquipment.safetyShoes ?? false} onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("Kõrvaklapid")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" name="earProtection" checked={protectiveEquipment.earProtection ?? false} onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("Respiraator filtriga")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" name="respiratorWithFilter" checked={protectiveEquipment.respiratorWithFilter ?? false} onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("Keevitusülikond")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" name="weldingSuit" checked={protectiveEquipment.weldingSuit ?? false} onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("Keevitusmask")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" name="weldingMask" checked={protectiveEquipment.weldingMask ?? false} onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("Värvijamask")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" name="paintingMask" checked={protectiveEquipment.paintingMask ?? false} onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("Eriliiki kaitseprillid")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" name="safetyGlasses" checked={protectiveEquipment.safetyGlasses ?? false} onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>

                        <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Description")}</Label>
                        <textarea className="material-textarea-description" style={{ width: "100%" }} value={protectiveEquipment.description ? protectiveEquipment.description : ""} name="description" placeholder={t("Vajadusel saab siia midagi kirjutada")} maxLength={200}
                            onChange={(e: any) => setProtectiveEquipment({ ...protectiveEquipment, description: e.target.value })} />

                        {/*                         <div style={{ marginTop: "2%", border: "1px solid #dee2e6", borderRadius: ".25rem", height: "200px", cursor: "pointer", width: 600 }}>
                            {(pic !== "" && pic !== null && pic !== undefined) ?
                                <img src={`${pic}`} alt="Signature" />
                                :
                                <SignatureCanvas ref={signatureCanvasRef} canvasProps={{ height: 200, width: 600 }} backgroundColor='white' onEnd={handleSaveSignature} />
                            }
                        </div> */}
                    </>
                }
            </form>
        </div>
    )
}

export default SelectPerson

