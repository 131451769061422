import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import CheckboxGroup from '../common/CheckboxGroup';
import { MeasurementField } from '../common/MeasurementField';
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig';
import { useAhuDataContext } from '../../../../context/ahuData';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker, LocalizationProvider, DesktopDatePicker, TimeClockSlotsComponentsProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export const materialTheme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: "10px",
                    color: "white"
                }
            }
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#c00',
                }
            }
        },
    },
    typography: {
        fontFamily: "inherit",
    },
    palette: {
        primary: {
            main: '#c00',
            contrastText: "#fff !important"
        },
        secondary: {
            main: '#c00',
            contrastText: "#fff !important"
        },
        text: {
            primary: '#202020cc',
            secondary: '#c00',
            disabled: '#000',
        },
    },
});


const MeasurementsFormCooler = () => {
    const { electricalMeasurementData, setElectricalMeasurementData } = useAhuDataContext();
    const { t } = useTranslation();
    const convertTimeStringToDate = (timeString: string) => {
        if (timeString === undefined) return;
        const date = new Date();
        date.setHours(parseInt(timeString.split(":")[0]));
        date.setMinutes(parseInt(timeString.split(":")[1]));

        return date;
    }

    const convertDateStringToDate = (dateString: string) => {
        if (dateString === undefined) return;
        const date = new Date();
        date.setFullYear(parseInt(dateString.split(".")[2]));
        date.setMonth(parseInt(dateString.split(".")[1]) - 1);
        date.setDate(parseInt(dateString.split(".")[0]));

        return date;
    }

    const [pressureTestStartTime, setPressureTestStartTime] = useState(convertTimeStringToDate(electricalMeasurementData.measurements["pressureStartTime-cp"]?.value) ?? null);
    const [pressureTestEndTime, setPressureTestEndTime] = useState(convertTimeStringToDate(electricalMeasurementData.measurements["pressureEndTime-cp"]?.value) ?? null);
    const [vacuumTestStartTime, setVacuumTestStartTime] = useState(convertTimeStringToDate(electricalMeasurementData.measurements["vacuumStartTime-cp"]?.value) ?? null);
    const [vacuumTestEndTime, setVacuumTestEndTime] = useState(convertTimeStringToDate(electricalMeasurementData.measurements["vacuumEndTime-cp"]?.value) ?? null);

    const [pressureTestStartDate, setPressureTestStartDate] = useState<Date | null>(convertDateStringToDate(electricalMeasurementData.measurements["pressureStartDate-cp"]?.value) ?? null);
    const [pressureTestEndDate, setPressureTestEndDate] = useState<Date | null>(convertDateStringToDate(electricalMeasurementData.measurements["pressureEndDate-cp"]?.value) ?? null);
    const [vacuumTestStartDate, setVacuumTestStartDate] = useState<Date | null>(convertDateStringToDate(electricalMeasurementData.measurements["vacuumStartDate-cp"]?.value) ?? null);
    const [vacuumTestEndDate, setVacuumTestEndDate] = useState<Date | null>(convertDateStringToDate(electricalMeasurementData.measurements["vacuumEndDate-cp"]?.value) ?? null);

    const handleTimeChange = (propertyName: string, value: Date, setStateProp: React.Dispatch<React.SetStateAction<Date | null>>) => {
        setStateProp(value)
        setElectricalMeasurementData({
            ...electricalMeasurementData,
            "measurements": { ...electricalMeasurementData.measurements, [propertyName]: { ...electricalMeasurementData.measurements[propertyName], value: value.toLocaleTimeString("et-EE", { hour: '2-digit', minute: '2-digit' }), statusNew: 'OK' } }
        })
    }
    const handleDateChange = (propertyName: string, value: Date, setStateProp: React.Dispatch<React.SetStateAction<Date | null>>) => {
        setStateProp(value)
        setElectricalMeasurementData({
            ...electricalMeasurementData,
            "measurements": { ...electricalMeasurementData.measurements, [propertyName]: { ...electricalMeasurementData.measurements[propertyName], value: value.toLocaleDateString("et-EE"), statusNew: 'OK' } }
        })
    }


    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("cooler protocol")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("PressureTest")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pressureEndPressure-cp"
                    />
                </div>

                <div style={{ display: "flex", justifyContent: "center", gap: "25px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={materialTheme}>
                            <DesktopDatePicker className='time-picker' label={t("StartDate")}
                                value={pressureTestStartDate}
                                onChange={(newValue: any) => handleDateChange("pressureStartDate-cp", newValue, setPressureTestStartDate)}
                            />
                        </ThemeProvider>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={materialTheme}>
                            <DesktopDatePicker className='time-picker' label={t("EndDate")}
                                value={pressureTestEndDate}
                                onChange={(newValue: any) => handleDateChange("pressureEndDate-cp", newValue, setPressureTestEndDate)}
                            />
                        </ThemeProvider>
                    </LocalizationProvider>
                </div>
                <div style={{ display: "flex", justifyContent: "center", gap: "25px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker className='time-picker' label={t("StartTime")} format="HH:mm"
                            value={pressureTestStartTime}
                            onChange={(newValue: any) => handleTimeChange("pressureStartTime-cp", newValue, setPressureTestStartTime)} />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker className='time-picker' label={t("EndTime")} format="HH:mm"
                            value={pressureTestEndTime}
                            onChange={(newValue: any) => handleTimeChange("pressureEndTime-cp", newValue, setPressureTestEndTime)} />
                    </LocalizationProvider>
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value range" }} gap="10px" properyName="pressureEndPressure-cp" showValue={true} unit="Bar" placeholderKey={t("EndPressure")} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("VacuumTest")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="vacuumEndPressure-cp"
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "center", gap: "25px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={materialTheme}>
                            <DesktopDatePicker className='time-picker' label={t("StartDate")}
                                value={vacuumTestStartDate}
                                onChange={(newValue: any) => handleDateChange("vacuumStartDate-cp", newValue, setVacuumTestStartDate)}
                            />
                        </ThemeProvider>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ThemeProvider theme={materialTheme}>
                            <DesktopDatePicker className='time-picker' label={t("EndDate")}
                                value={vacuumTestEndDate}
                                onChange={(newValue: any) => handleDateChange("vacuumEndDate-cp", newValue, setVacuumTestEndDate)}
                            />
                        </ThemeProvider>
                    </LocalizationProvider>
                </div>
                <div style={{ display: "flex", justifyContent: "center", gap: "25px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker className='time-picker' label={t("StartTime")} format="HH:mm"
                            value={vacuumTestStartTime}
                            onChange={(newValue: any) => handleTimeChange("vacuumStartTime-cp", newValue, setVacuumTestStartTime)} />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker className='time-picker' label={t("EndTime")} format="HH:mm"
                            value={vacuumTestEndTime}
                            onChange={(newValue: any) => handleTimeChange("vacuumEndTime-cp", newValue, setVacuumTestEndTime)} />
                    </LocalizationProvider>
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value range" }} gap="10px" properyName="vacuumEndPressure-cp" showValue={true} unit="mbar" placeholderKey={t("EndPressure")} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("StartRefrigerant")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="begginingRefrigerant-cp"
                    />
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value range" }} gap="10px" properyName="begginingRefrigerant-cp" showValue={true} unit="kg" placeholderKey={t("Quantity")} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("EndRefrigerant")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="endRefrigerant-cp"
                    />
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value range" }} gap="10px" properyName="endRefrigerant-cp" showValue={true} unit="kg" placeholderKey={t("Quantity")} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("OverallRefill")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="overallRefrigerant-cp"
                    />
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value range" }} gap="10px" properyName="overallRefrigerant-cp" showValue={true} unit="kg" placeholderKey={t("Quantity")} />

            </div>
        </form>
    )
}

export default MeasurementsFormCooler